import { intro as introFR, programs as programsFR } from './fr';
import { intro as introEN, programs as programsEN } from './en';

export const intro = {
  fr: introFR,
  en: introEN,
};

export const programs = {
  fr: programsFR,
  en: programsEN
};
