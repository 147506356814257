export const intro = {
  title: 'Which of these statements concerns you?',
  options: [{
    id: 1,
    name: 'I often have my jaw contracted',
    icon: null,
    good: true,
    chapterNumber: 2,
    episodeNumber: 1
  }, {
    id: 2,
    name: 'I frown regularly',
    icon: null,
    good: true,
    chapterNumber: 3,
    episodeNumber: 1
  }, {
    id: 3,
    name: 'I have my head constantly in my shoulders',
    icon: null,
    good: true,
    chapterNumber: 4,
    episodeNumber: 1
  }, {
    id: 4,
    name: 'The three answers, I\'m afraid ...',
    icon: null,
    good: true,
    chapterNumber: 5,
    episodeNumber: 1
  }, {
    id: 5,
    name: 'Sorry but none',
    icon: null,
    good: true,
    chapterNumber: 1,
    episodeNumber: 1
  }]
}

export const programs = [{
  number: 1,
  title: 'Posturology',
  description: 'Welcome to this Mobile Learning ! This videos will help you understand your body better and how your equilibrium functions.',
  episodes: [
    {
      title: 'Understand your body better',
      description: 'If you are a professional athlete or someone who works in front of a computer, is it the same battle ? Repetitive movements, imposed rhythms, dehydration...Posturology will help you to diminish the negative impacts of your prolonged postures.',
      video: 'https://player.vimeo.com/external/426188101.m3u8?s=96d63b60645841e4d2c2ba62d9f069920b3e753c',
      number: 1,
    }, {
      title: 'Mobility tests',
      description: 'Take 2 minutes to observe the range of motion of your shoulders, hips and spinal column. Built for movement, our body needs to maintain its mobility.  It\'s time to verify your range of motion.',
      number: 2,
      video: 'https://player.vimeo.com/external/397453407.m3u8?s=19a231648bd97eae3168ebcdd7fdc06468aaf578',
    }
  ],
  quizzes: [{
    title: 'Which are indicators of posture?',
    options: [{
      id: 1,
      name: 'The eyes',
      icon: null,
      good: true,
    }, {
      id: 2,
      name: 'The internal ear',
      icon: null,
      good: true,
    }, {
      id: 3,
      name: 'The skin',
      icon: null,
      good: true,
    }, {
      id: 4,
      name: 'The small intestine',
      icon: null,
      good: false,
    }, {
      id: 5,
      name: 'Teeth contact',
      icon: null,
      good: true,
    }, {
      id: 6,
      name: 'The muscles',
      icon: null,
      good: true,
    }]
  }]
}, {
  number: 2,
  title: 'Vision',
  description: 'What is the link between vision and neck pain ?',
  episodes: [
    {
      title: 'Vision',
      description: 'What is the link between vision and neck pain ? How is it that we started constantly walking on our two feet ? Discover how your posture and therefore your muscles, help your eyes...',
      number: 1,
      video: 'https://player.vimeo.com/external/427028387.m3u8?s=bef97641ee12bb10cc5fb3dbefc21013a292541b',
    }, {
      title: 'The sticker',
      description: 'Here is a simple conditioning technique to help you get rid of bad habits and to program new, healthier ones.',
      number: 2,
      video: 'https://player.vimeo.com/external/397453559.m3u8?s=7df21c84a0caf6ea7dc99ec938abf7fa392293f1',
    }, {
      title: 'Palming',
      description: 'Discover a very effective method to give not only your eyes, but also your brain, a break. Sit down and let yourself be guided.',
      number: 3,
      video: 'https://player.vimeo.com/external/397453680.m3u8?s=4b58faa392c91d8d83e7064a97d3580b5ef134a8',
    }, {
      title: 'Yoga for the eyes',
      description: 'Try these eye muscle stretching exercises. Be careful to do it gradually because this is a very powerful exercise.',
      number: 4,
      video: 'https://player.vimeo.com/external/397453832.m3u8?s=ba7aa88e6c7605ff881ab923b083ae5279b9e832',
    }
  ],
  quizzes: [{
    title: 'Why take « eye breaks »?',
    options: [{
      id: 1,
      name: 'To allow the scalp to relax and thereby have beautiful hair',
      good: false,
    }, {
      id: 2,
      name: 'In order to change the tension in the eye muscles',
      good: true,
    }, {
      id: 3,
      name: 'To delay the onset of presbyopia',
      good: false,
    }, {
      id: 4,
      name: 'To digest fat better',
      good: false,
    }, {
      id: 5,
      name: 'To prevent migraines and neck pain',
      good: true,
    }]
  }]
}, {
  number: 3,
  title: 'Dental contact and posture',
  description: 'Discover the link between posture, and teeth when they are in contact with each other.',
  episodes: [
    {
      title: 'Dental contact and posture',
      description: 'Discover the link between posture, and teeth when they are in contact with each other. This is the often-unsuspected origin of a lot of muscle pain and posture problems.  After this video, you will say good-bye to chewing gum.',
      number: 1,
      video: 'https://player.vimeo.com/external/397451519.m3u8?s=ac143148c073f10afff0a61fdde3bd453143598b'
    }, {
      title: 'Bruxism',
      description: 'Whether conscious or unconscious, bruxism is not your friend ! Get rid of your bruxism thanks to the advice provided.',
      number: 2,
      video: 'https://player.vimeo.com/external/397453967.m3u8?s=7fbd07d43059e1097f67d19930b423e3f710dc63'
    }
  ],
  quizzes: [{
    title: 'Why relax teeth contact every day?',
    options: [{
      id: 1,
      name: 'To avoid overcharging our brain with information about our posture',
      good: true,
    }, {
      id: 2,
      name: 'To have good breath and a beautiful smile',
      good: false,
    }, {
      id: 3,
      name: 'To save energy and to relax the chewing and posture muscles',
      good: true,
    }, {
      id: 4,
      name: 'To avoid feeling bloated',
      good: false,
    }]
  }]
},  {
  number: 4,
  title: 'Muscles',
  description: 'Do you know how a muscle works and what its needs are? Discover all you need to know about this outstanding creation!',
  episodes: [
    {
      title: 'Muscles',
      description: 'Do you know how a muscle works and what its needs are? Discover all you need to know about this outstanding creation! Serve yourself a glass of water – here\'s to your health!',
      number: 1,
      video: 'https://player.vimeo.com/external/397452482.m3u8?s=beb68c68aeda7bd82d21ef991f65c74e7e7c81a4'
    }, {
      title: 'Forearm stretches',
      description: 'Often forgotten, your forearms deserve all of your attention. They are constantly called upon at work; here you will discover how to discreetly stretch them at the office.',
      number: 2,
      video: 'https://player.vimeo.com/external/397454566.m3u8?s=555e90cc2b09bdc9144347eeb6b23dc636cb7269'
    }, {
      title: 'Stretching of the trapezius muscle',
      description: 'This muscle is constantly solicited when you work on a screen. Therefore, give yourself some time to relieve it by breathing and keeping your eyes open.',
      number: 3,
      video: 'https://player.vimeo.com/external/397454739.m3u8?s=f9e593ebed84eea39c96ec3e371015b13816277b'
    }, {
      title: 'Stretching of the psoas',
      description: 'Discover the muscle present on each side of your pelvis. It allows the flexion of the torso towards the front.  It is also shortened when in a sitting position for a long time and is one culprit of lower back pain.',
      number: 4,
      video: 'https://player.vimeo.com/external/397454295.m3u8?s=79a0034e23ba0960f4f25117cfd40ad34fb7b201'
    }, {
      title: 'Hamstring stretches',
      description: 'In order to relieve the pressure on your lower back, learn to stretch your hamstrings. This is often recommended for prolonged standing or sitting positions!',
      number: 5,
      video: 'https://player.vimeo.com/external/397454181.m3u8?s=660e910453c6e4251a2ce9be379c78263edcdeb5'
    }, {
      title: 'Use of a foam roller',
      description: 'Create sparks with this little foam cylinder! It is your best ally to mobilize and maintain healthy body tissue.',
      number: 6,
      video: 'https://player.vimeo.com/external/427024390.m3u8?s=12af6b8e1379e4d88e08a05adbabfdb5fa0d3963'
    }
  ],
  quizzes: [{
    title: 'What are the keys to success for stretching?',
    options: [{
      id: 1,
      name: 'To be present while feeling the sensations and to work the stretching zone for at least 90 seconds.',
      good: true,
    }, {
      id: 2,
      name: 'Visualize a Savoyard fondue during the exercise',
      good: false,
    }, {
      id: 3,
      name: 'To be in expiratory apnea and pull on the muscles as much as possible and with rhythm',
      good: false,
    }, {
      id: 4,
      name: 'Practice as often as possible, at least one stretch per day',
      good: true,
    }]
  }, {
    title: 'What are the benefits of using a form roller?',
    options: [{
      id: 1,
      name: 'To reconnect the cerebral hemispheres between themselves',
      good: false,
    }, {
      id: 2,
      name: 'To loosen the fascia, a tissue which maintains our muscles',
      good: true,
    }, {
      id: 3,
      name: 'To drain the toxins trapped in our muscles',
      good: true,
    }, {
      id: 4,
      name: 'To free tissue trauma memories',
      good: true,
    }]
  }]
}, {
  number: 5,
  title: 'Daily postures',
  description: 'Why is it so important to not be seated all day long ? Discover the benefits of moving and verticality.',
  episodes: [
    {
      title: 'Daily postures',
      description: 'Why is it so important to not be seated all day long? Discover the benefits of moving and verticality. Do you know on average how many hours we are seated per day?',
      number: 1,
      video: 'https://player.vimeo.com/external/397452811.m3u8?s=354df6e49ffe39ff0e410813ae6884b40f096fad',
    }, {
      title: 'Good posture in front of a computer screen',
      description: 'Mandatory, simple and easy to do! To minimize your accumulated daily tension in front of a computer screen or at a desk, follow the advice provided to adapt your work space to your physical characteristics.',
      number: 2,
      video: 'https://player.vimeo.com/external/397454850.m3u8?s=486271f0fdca172176dec6ebae53029669902391',
    }, {
      title: 'Posture when relaxing',
      description: 'Even and especially when at home, your posture impacts your health. So, respect your verticality as often as possible!',
      number: 3,
      video: 'https://player.vimeo.com/external/397455048.m3u8?s=c84dd878c66966a116b80a3d608bbc42a20c01f1'
    }
  ],
  quizzes: [{
    title: 'The keys to good posture in front of a computer',
    options: [{
      id: 1,
      name: 'The legs are uncrossed, feet on the floor',
      good: true,
    }, {
      id: 2,
      name: 'The elbows above or at the same level as the desk',
      good: true,
    }, {
      id: 3,
      name: 'The eyes at the upper level of the screen',
      good: true,
    }, {
      id: 4,
      name: 'Keep your mouth open and your eyes closed.',
      good: false,
    }]
  }, {
    title: 'What are good posture habits when relaxing',
    options: [{
      id: 1,
      name: 'Think verticality: the head in a neutral position on the shoulders',
      good: true,
    }, {
      id: 2,
      name: 'Move regularly and blink your eyes',
      good: true,
    }, {
      id: 3,
      name: 'Always have your feet touching each other or your legs crossed; it\'s up to you',
      good: false,
    }, {
      id: 4,
      name: 'Keep the position as long as possible',
      good: false,
    }, {
      id: 5,
      name: 'Don\'t move from your greatest range of motion for a few hours',
      good: false,
    }]
  }]
}, {
  number: 6,
  title: 'Yoga posturology',
  description: 'Based on research and exchanges between Julien Paccaud, osteopath posturologist, and Guillaume Body-Lawson, Hatha Yoga trainer, this yoga is perfectly adapted to fight against being sedentary.',
  episodes: [
    {
      title: 'Yoga posturology',
      description: 'Based on research and exchanges between Julien Paccaud, osteopath posturologist, and Guillaume Body-Lawson, Hatha Yoga trainer, this yoga is perfectly adapted to fight against being sedentary.  You only need a yoga mat and ….some action.',
      number: 1,
      video: 'https://player.vimeo.com/external/426188101.m3u8?s=96d63b60645841e4d2c2ba62d9f069920b3e753c'
    }
  ]
}];

