import React from 'react';
import useGlobalHook from 'use-global-hook';


import * as actions from './actions';
import { programs } from '../datas';

const chapters = programs.fr.map((chapter) => {
  return {
    number: chapter.number,
    episodes: chapter.episodes.map(episode => ({
      number: episode.number,
      progress: 0,
    })),
    quizzDone: false,
    quizzScore: 0,
    // quizzes: chapter.quizzes || [],
  }
});

const initialState = {
  chapters,
  introDone: false,
  status: 'INTIAL',
  lastFetch: null,
};

const customSetState = (store, setState) => (newState) => {
  const newGlobalState = {
    ...store.state,
    ...newState,
  };

  localStorage.setItem('mbeState', JSON.stringify(newGlobalState));

  setState(newState);
};

const initializer = async store => {
  if (typeof window !== 'undefined' && window.localStorage) {
    const datas = JSON.parse(localStorage.getItem('mbeState') || null);
    if (datas) {
      store.setState(datas);
    }
    store.setState = customSetState(store, store.setState);
  }
  await actions.refreshActivity(store);
};

const useGlobal = useGlobalHook(React, initialState, actions, initializer);

export default useGlobal;
