export const intro = {
  title: 'Laquelle de ces affirmations vous concerne ?',
  options: [{
    id: 1,
    name: 'J’ai souvent la machoire contractée',
    icon: null,
    good: true,
    chapterNumber: 2,
    episodeNumber: 1
  }, {
    id: 2,
    name: 'Je fronce régulièrement des sourcils',
    icon: null,
    good: true,
    chapterNumber: 3,
    episodeNumber: 1
  }, {
    id: 3,
    name: 'J’ai constamment la tête dans les épaules',
    icon: null,
    good: true,
    chapterNumber: 4,
    episodeNumber: 1
  }, {
    id: 4,
    name: 'Les 3, j’en ai bien peur…',
    icon: null,
    good: true,
    chapterNumber: 5,
    episodeNumber: 1
  }, {
    id: 5,
    name: 'Désolé mais aucune',
    icon: null,
    good: true,
    chapterNumber: 1,
    episodeNumber: 1
  }]
}

export const programs = [{
  number: 1,
  title: 'La posturologie',
  description: 'Bienvenue dans ce Mobile Learning ! Ces vidéos vont vous aider à mieux comprendre votre corps et notamment comment fonctionne votre équilibre.',
  episodes: [
    {
      title: 'Comprendre son équilibre',
      description: 'Comprendre votre corps et notamment comment fonctionne votre équilibre. Sportif de haut niveau ou opérateur devant son ordinateur, même combat ? Mouvements répétitifs, rythmes imposés, déshydratation… La posturologie va vous permettre de diminuer les impacts négatifs de vos postures prolongées.',
      video: 'https://player.vimeo.com/external/374250028.m3u8?s=a0a4565e3a264162cc5fbb33963f2cf39f415390',
      number: 1,
    }, {
      title: 'Tests d’amplitudes de mouvement',
      description: 'Prenez 2 minutes pour observer les amplitudes articulaires de vos épaules, hanches et colonne vertébrale. Construit pour le mouvement, notre corps a besoin d’être entretenu dans toutes ces mobilités. C’est l’heure de faire un petit bilan de vos mobilités.',
      number: 2,
      video: 'https://player.vimeo.com/external/374264687.m3u8?s=dc5359a69d7885c2b7f4de0953de885193108823',
    }
  ],
  quizzes: [{
    title: 'Quelles sont les informateurs de la posture ?',
    options: [{
      id: 1,
      name: 'Les yeux',
      icon: null,
      good: true,
    }, {
      id: 2,
      name: 'L\'oreille interne',
      icon: null,
      good: true,
    }, {
      id: 3,
      name: 'La peau',
      icon: null,
      good: true,
    }, {
      id: 4,
      name: 'l\'intestin grêle',
      icon: null,
      good: false,
    }, {
      id: 5,
      name: 'Le contact dentaire',
      icon: null,
      good: true,
    }, {
      id: 6,
      name: 'Les muscles',
      icon: null,
      good: true,
    }]
  }]
}, {
  number: 2,
  title: 'La vision',
  description: 'Découvrez pourquoi toute votre posture, et par conséquent vos muscles, sont au service de vos yeux.',
  episodes: [
    {
      title: 'La vision',
      description: 'Quels sont les liens entre la vision et les douleurs cervicales ? Pourquoi sommes-nous devenus des bipèdes permanents ? Découvrez pourquoi toute votre posture, et par conséquent vos muscles, sont au service de vos yeux.',
      number: 1,
      video: 'https://player.vimeo.com/external/374259303.m3u8?s=64a3c11a3129f2438f6948240de68ef02b84e142',
    }, {
      title: 'La gommette',
      description: 'Voici une technique simple de conditionnement pour vous libérer de mauvaises habitudes et d’en programmer de nouvelles plus vertueuses.',
      number: 2,
      video: 'https://player.vimeo.com/external/374264910.m3u8?s=3f06b8cf2ed948fce061eb78bc427f3f0e160c2b',
    }, {
      title: 'Le palming',
      description: 'Découvrez une technique très efficace pour offrir une pause à vos yeux, mais aussi à votre cerveau... Asseyez-vous et laissez-vous guider',
      number: 3,
      video: 'https://player.vimeo.com/external/374266538.m3u8?s=130bb917c277bd560fd6d70a5ebc0be6b2964d69',
    }, {
      title: 'Le yoga des yeux',
      description: 'Expérimentez ici des étirements des muscles de l’œil. Attention à y aller graduellement car c’est une technique très puissante',
      number: 4,
      video: 'https://player.vimeo.com/external/374345477.m3u8?s=11e939eccde57347553a54ea201c15231e85dbe5',
    }
  ],
  quizzes: [{
    title: 'Pourquoi faire des pauses oculaires ?',
    options: [{
      id: 1,
      name: 'Pour permettre au cuir chevelu de se relâcher, et ainsi avoir de beaux cheveux',
      good: false,
    }, {
      id: 2,
      name: 'Afin de changer les tensions musculaires oculaires',
      good: true,
    }, {
      id: 3,
      name: 'Pour repousser l\'apparition de la presbytie',
      good: false,
    }, {
      id: 4,
      name: 'Pour mieux digérer les graisses',
      good: false,
    }, {
      id: 5,
      name: 'Pour prévenir l’apparition des migraines ophtalmiques et des douleurs cervicales',
      good: true,
    }]
  }]
}, {
  number: 3,
  title: 'Le contact dentaire',
  description: 'Découvrez les rapports qu’entretiennent la posture et les dents lorsqu’elles sont en contact. Voici l’origine, souvent insoupçonnée, de beaucoup de douleurs musculaires ou de troubles posturaux.',
  episodes: [
    {
      title: 'Le contact dentaire et la posture',
      description: 'Découvrez les rapports qu’entretiennent la posture et les dents lorsqu’elles sont en contact. Voici l’origine, souvent insoupçonnée, de beaucoup de douleurs musculaires ou de troubles posturaux. Après cette vidéo, vous allez dire au revoir aux chewing-gum...',
      number: 1,
      video: 'https://player.vimeo.com/external/374261934.m3u8?s=5b75af7da56ef04ad1029ae0e23e02c1c4b0b4a4'
    }, {
      title: 'Les bruxismes',
      description: 'Conscient ou inconscient le bruxisme n’est pas votre ami ! Débarrassez-vous de votre bruxisme grâce à nos conseils',
      number: 2,
      video: 'https://player.vimeo.com/external/374350532.m3u8?s=8aae75a4644b16c933e9a41d2beb5bb35ebb5c94'
    }
  ],
  quizzes: [{
    title: 'Pourquoi relâcher le contact dentaire tous les jours ?',
    options: [{
      id: 1,
      name: 'Pour éviter de surcharger notre cerveau d\'informations posturales',
      good: true,
    }, {
      id: 2,
      name: 'Pour avoir bonne haleine et un beau sourire',
      good: false,
    }, {
      id: 3,
      name: 'Pour économiser son énergie et relâcher ses muscles masticateurs et posturaux',
      good: true,
    }, {
      id: 4,
      name: 'Pour éviter les ballonnements',
      good: false,
    }]
  }]
},  {
  number: 4,
  title: 'Les muscles',
  description: 'Savez-vous comment fonctionne un muscle, quels sont ses besoins ? Découvrez tout ce que vous devez savoir sur ce bijou de conception !',
  episodes: [
    {
      title: 'Les muscles',
      description: 'Savez-vous comment fonctionne un muscle, quels sont ses besoins ? Découvrez tout ce que vous devez savoir sur ce bijou de conception ! Servez-vous un verre d’eau et… A votre santé !!!',
      number: 1,
      video: 'https://player.vimeo.com/external/374262910.m3u8?s=5a050949aef39149d2afe38e1ef544e79a290848'
    }, {
      title: 'Étirements des avant-bras',
      description: 'Trop souvent oubliés, vos avant-bras méritent toute votre attention. Ils sont sollicités tous les jours au travail, ici vous découvrirez comment les étirer discrètement sur votre bureau.',
      number: 2,
      video: 'https://player.vimeo.com/external/374350656.m3u8?s=bd7d7979ef8efe6619cc1d823782f5076248100c'
    }, {
      title: 'Étirement du trapèze',
      description: 'Ce muscle est en constante sollicitation lorsque nous travaillons sur écran. Alors offrez-vous un moment pour détendre ce muscle en respirant et surtout en gardant les yeux ouverts.',
      number: 3,
      video: 'https://player.vimeo.com/external/374350702.m3u8?s=d822fc9a21475bb5ef3fd56cb11a3b767540e8d2'
    }, {
      title: 'Étirements des psoas',
      description: 'Découvrez ce muscle présent de chaque côté de notre bassin. Il nous permet la flexion du buste vers l’avant. Il est donc souvent raccourci en position assise prolongée. Il est aussi un grand responsable des lombalgies.',
      number: 4,
      video: 'https://player.vimeo.com/external/374350618.m3u8?s=541f9ad34626d88aae968b5029950d7ec7475689'
    }, {
      title: 'Étirements des ischio-jambiers',
      description: 'Pour libérer la pression sur vos lombaires, apprenez à étirer vos muscles postérieurs des cuisses. Particulièrement recommandé pour les postures debout prolongées ou même assises !',
      number: 5,
      video: 'https://player.vimeo.com/external/374350582.m3u8?s=11641f3a77d7660ca22e8d82bd65eea0a1b26cba'
    }, {
      title: 'L’utilisation du foam roller',
      description: 'Avec ce petit cylindre en mousse, faites des étincelles ! Il est votre meilleur allié pour mobiliser et entretenir votre santé tissulaire',
      number: 6,
      video: 'https://player.vimeo.com/external/374350758.m3u8?s=f67fd57ab30f08137b053f03d13e065c3495f09d'
    }
  ],
  quizzes: [{
    title: 'Quelles sont les clés de la réussite des étirements ?',
    options: [{
      id: 1,
      name: 'Etre présent à ses sensations, et respirer sur la zone d\'étirement 90 sec minimum',
      good: true,
    }, {
      id: 2,
      name: 'Visualiser une fondue savoyarde pendant l\'exercice',
      good: false,
    }, {
      id: 3,
      name: 'Etre en apnée expiratoire et tirer sur le muscle au maximum et en rythme',
      good: false,
    }, {
      id: 4,
      name: 'Pratiquer le plus souvent possible au moins un étirement une fois par jour',
      good: true,
    }]
  }, {
    title: 'Quels sont les bénéfices de l\'utilisation du foam roller ?',
    options: [{
      id: 1,
      name: 'Une reconnexion des hémisphères cérébraux entre eux',
      good: false,
    }, {
      id: 2,
      name: 'Une libération des fascias, tissu de maintien de nos muscles',
      good: true,
    }, {
      id: 3,
      name: 'Un drainage des toxines emprisonnées dans les muscles',
      good: true,
    }, {
      id: 4,
      name: 'Une libération de mémoires des traumatismes tissulaires',
      good: true,
    }]
  }]
}, {
  number: 5,
  title: 'Nos postures',
  description: 'Pourquoi est-ce aussi important de ne pas rester assis toute la journée ? Découvrez les bénéfices de la mise en mouvement et de la verticalité.',
  episodes: [
    {
      title: 'Nos postures au quotidien',
      description: 'Pourquoi est-ce aussi important de ne pas rester assis toute la journée ? Découvrez les bénéfices de la mise en mouvement et de la verticalité. Savez-vous combien d’heures en moyenne quotidienne restons-nous en position assise ?',
      number: 1,
      video: 'https://player.vimeo.com/external/374400733.m3u8?s=1a44ed7b89c8c12fcb4ee50b262d9e7e2684a234',
    }, {
      title: 'Une bonne posture devant l’ordinateur',
      description: 'Indispensable, simple et facile à appliquer ! Pour minimiser vos tensions quotidiennement accumulées devant votre poste de travail, suivez nos conseils pour adapter votre poste de travail à votre physionomie.',
      number: 2,
      video: 'https://player.vimeo.com/external/374350823.m3u8?s=6507c4e1c2d374491cc54c46b773e8295ca96a1e',
    }, {
      title: 'Les postures de repos',
      description: 'Même à la maison et surtout à la maison, vos postures ont une conséquence sur votre santé. Alors soyez vigilent respectez votre verticalité au maximum.',
      number: 3,
      video: 'https://player.vimeo.com/external/374350966.m3u8?s=45367634b3f3530f80876d64a191aa655e7a471f'
    }
  ],
  quizzes: [{
    title: 'Quelles sont les clés d’une bonne position devant l\'ordinateur ?',
    options: [{
      id: 1,
      name: 'Les jambes décroisées, pieds au sol',
      good: true,
    }, {
      id: 2,
      name: 'Les coudes au dessus ou au même niveau que le bureau',
      good: true,
    }, {
      id: 3,
      name: 'Les yeux à au niveau du bord supérieur de l’écran',
      good: true,
    }, {
      id: 4,
      name: 'Garder la bouche ouverte et les yeux fermés.',
      good: false,
    }]
  }, {
    title: 'Quelles sont les bonnes habitudes posturales de repos ?',
    options: [{
      id: 1,
      name: 'Penser verticalité, position neutre de la tête sur les épaules',
      good: true,
    }, {
      id: 2,
      name: 'Bouger régulièrement et clignez des yeux',
      good: true,
    }, {
      id: 3,
      name: 'Toujours avoir les pieds serrés ou les jambes croisées au choix',
      good: false,
    }, {
      id: 4,
      name: 'Garder la positionner en apnée le plus longtemps possible',
      good: false,
    }, {
      id: 5,
      name: 'Rester immobile au maximum de son amplitude articulaire pendant des heures',
      good: false,
    }]
  }]
}, {
  number: 6,
  title: 'Le yoga posturologique',
  description: 'Issu des recherches et échanges entre Julien Paccaud, ostéopathe posturologue, et Guillaume Body-Lawson, professeur de Hatha Yoga, ce flow de yoga est parfaitement calibré pour lutter contre la sédentarité.',
  episodes: [
    {
      title: 'Le yoga posturologique',
      description: 'Issu des recherches et échanges entre Julien Paccaud, ostéopathe posturologue, et Guillaume Body-Lawson, professeur de Hatha Yoga, ce flow de yoga est parfaitement calibré pour lutter contre la sédentarité. Manque plus qu’un tapis de yoga… et à l’action',
      number: 1,
      video: 'https://player.vimeo.com/external/374427134.m3u8?s=f8f37eb3a86bfc44da7ef6830f6ceadcb02ffcd6'
    }
  ]
}];

