import axios from 'axios';
import deepmerge from 'object-merge-advanced';

import auth from '../../services/auth';

const { GATSBY_API_DOMAIN: API_DOMAIN } = process.env;

export const setEpisodeProgress = (store, chapterNumber, episodeNumber, progress) => {
  console.log('--->', store, chapterNumber, episodeNumber, progress);
  const chapters = store.state.chapters;
  const chapterIndex = chapters.findIndex(c => c.number === chapterNumber);
  const chapter = { ...chapters[chapterIndex] };
  const episode = chapter.episodes.find(e => e.number === episodeNumber);
  episode.progress = progress;

  chapters[chapterIndex] = chapter;

  store.setState({ chapters });

  try {
    axios.post(`${API_DOMAIN}/activity`, {
      chapterNumber,
      episodeNumber,
      progress
    }, {
      headers: {
        'Authorization': `Bearer ${auth.token}`
      }
    });
  } catch (e) {
    console.log('*** setEpisodeProgress error', e);
  }
};

export const setQuizzDone = (store, chapterNumber, score) => {
  const chapters = store.state.chapters;
  const chapterIndex = chapters.findIndex(c => c.number === chapterNumber);
  const chapter = { ...chapters[chapterIndex] };

  chapter.quizzDone = true;
  chapter.quizzScore = score;
  chapters[chapterIndex] = chapter;
  
  store.setState({ chapters });
  try {
    axios.post(`${API_DOMAIN}/quizz`, {
      chapterNumber,
      score
    }, {
      headers: {
        'Authorization': `Bearer ${auth.token}`
      }
    });
  } catch(e) {
    console.log('*** setQuizzDone error', e);
  }
}

export const setIntroDone = (store) => {
  store.setState({ introDone: true });
  try {
    axios.post(`${API_DOMAIN}/intro`, {
      chapterNumber: 0,
      score: 1
    }, {
      headers: {
        'Authorization': `Bearer ${auth.token}`
      }
    });
  } catch(e) {
    console.log('*** setIntroDone error', e);
  }
}

let refreshingData = false;

export const refreshActivity = async (store) => {
  const { state } = store;
  const cacheTime = 5 * 60 * 1000;

  if (!auth.isLoggedIn() || refreshingData) {
    return;
  }

  if (state.status !== 'INITIAL' && (state.lastFetch + cacheTime > Date.now())) {
    console.log('**** keep data cache version');
    return;
  }

  refreshingData = true;
  
  try {
    const result = await axios.get(`${API_DOMAIN}/activities`, {
      headers: {
        'Authorization': `Bearer ${auth.token}`
      }
    });
    console.log('*** before merge', store.state.chapters);
    const chapters = store.state.chapters.map(chapter => {
      const test = result.data.chapters.find(c => c.number === chapter.number);
      console.log('***** foudn test', test);

      if (!test) {
        return chapter;
      }

      return deepmerge(chapter, test, {
        hardMergeKeys: 'number'
      });
    });

    store.setState({
      ...result.data,
      chapters,
      status: 'API',
      lastFetch: Date.now()
    });
    console.log('[!!] Got chapters from api', chapters);
  } catch (e) {
    console.log('[!!] Fetch api chapters error', e);
    refreshingData = false;

    await new Promise(resolve => setTimeout(resolve, 5000));
    return refreshActivity(store);
  }

  refreshingData = false;
}